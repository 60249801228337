import { deepEqual } from '@/helpers/utils'
import { useGAEventStore } from '@/store/GAEventTracking'

export default {
  install: (app) => {
    const store = useGAEventStore()

    const init = ({ el, arg, value, modifiers }) => {
      if (!value) return
      el._vue_gaEventType = arg ?? 'click'
      el._vue_gaEventValue = value
      const handler = () => {
        let value = el._vue_gaEventValue
        if (typeof value === 'string') {
          // value can be a string as a shorthand value
          value = {
            micrositeSectionContent: value,
          }
        }
        if (typeof value == 'function') value = value()
        store.pushActionEvent(
          {
            interaction: 'button_click', // default value
            ...value,
          },
          el
        )
      }
      el.addEventListener(el._vue_gaEventType, handler, {
        once: modifiers['once'] ?? false,
        capture: true, // it's required for the redirection button click - please don't remove this!
      })
      /// mimic the click event tracking for the middle mouse click
      if (el._vue_gaEventType === 'click') {
        el.addEventListener(
          'auxclick',
          (e) => {
            if (e.which !== 2 && e.button !== 4) return // only middle mouse button click
            handler()
          },
          {
            once: modifiers['once'] ?? false,
            capture: true,
          }
        )
      }
    }

    app.directive('ga-event', {
      mounted(el, { arg, value, modifiers }) {
        init({ el, arg, value, modifiers })
      },
      updated(el, { value, oldValue }) {
        if (typeof value === 'function') return
        if (deepEqual(value, oldValue)) return
        console.log('====>', value, oldValue)
        el._vue_gaEventValue = value
      },
    })
  },
}
