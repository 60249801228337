<template>
  <router-view />
</template>

<style lang="scss">
  html,
  body {
    margin: 0;
  }

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  #gnt_center {
    display: none !important;
  }
  .gnt-notice-tabs {
    pointer-events: none;
  }
  .gnt-notice-tab {
    pointer-events: all;
  }
  .gnt-nav {
    display: none !important;
  }
</style>

<script setup>
  import { onMounted } from 'vue'

  onMounted(() => {
    window.version = `MS Landing version: ${VUE_APP_VERSION}`
  })
</script>
