import { useGAEventStore } from '@/store/GAEventTracking'
import { useWindowSize, useElementSize, watchDebounced } from '@vueuse/core'
import { ObserveVisibility } from 'vue-observe-visibility'
import { deepEqual } from '@/helpers/utils'

const thresholdForViewport = 0.5

export default {
  install: (app) => {
    const store = useGAEventStore()
    const settings = (el) => ({
      intersection: { threshold: el._vue_visibilityState_threshold ?? 0.5 },
      throttle: 1000,
      // once: true,
      callback: (isVisible) => {
        if (!isVisible) return
        let value = el._vue_gaSectionView_Value
        if (typeof value === 'string') {
          // value can be string as shorthand value
          value = {
            micrositeSectionContent: value,
          }
        }
        store.pushSectionViewEvent(el, value)
      },
    })
    app.directive('ga-section-view', {
      mounted(el, { value }) {
        const { height: winHeight } = useWindowSize()
        const { height } = useElementSize(el)
        watchDebounced(
          [height, winHeight],
          () => {
            // console.log(value, height.value, el.dataset)
            if (height.value > winHeight.value) {
              const newThreshold = (winHeight.value * thresholdForViewport) / height.value
              // console.log(value, 'new threshold', newThreshold)
              el._vue_visibilityState_threshold = newThreshold
              const state = el._vue_visibilityState
              state.createObserver(settings(el))
            }
          },
          { debounce: 100, maxWait: 400 }
        )

        el._vue_gaSectionView_Value = value
        ObserveVisibility.beforeMount.call(this, el, { value: settings(el) })
      },
      updated(el, { value, oldValue }) {
        if (deepEqual(value, oldValue)) return
        el._vue_gaSectionView_Value = value
        // const state = el._vue_visibilityState
        // if (state) {
        //   state.destroyObserver()
        //   delete el._vue_visibilityState
        // }
        // ObserveVisibility.beforeMount.call(this, el, { value: settings(el) }) // to fire again even though it's already fired (it's frozen by once option )
      },
      unmounted: ObserveVisibility.unmounted,
    })
  },
}
