import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGAEventStore = defineStore('GAEventTracking', () => {
  const lastSectionView = ref('')
  const stackHistory = []

  const getSectionDataSets = (el) => {
    while (el.dataset.sectionNumber == undefined && el.parentElement != null) {
      el = el.parentElement
    }
    return {
      event: 'standardMicrositeTrackingEvent',
      micrositeSectionNumber: Number(el.dataset.sectionNumber),
      micrositeSectionName: el.dataset.sectionName,
      ...(el.dataset.eventDetails ? { eventDetails: el.dataset.eventDetails } : {}),
      ...(el.dataset.interaction ? { interaction: el.dataset.interaction } : {}),
    }
  }

  function hasDataset(el, targetDataset) {
    while (el && (el.dataset === undefined || el.dataset[targetDataset] === undefined) && el.parentElement != null) {
      el = el.parentElement
    }
    return el.dataset && el.dataset[targetDataset] !== undefined
  }

  // it should fire an one time event per lifecycle, but needs to be used for the floating button tracking
  const pushSectionViewEvent = (el, additionalData) => {
    const sections = getSectionDataSets(el)

    // singleton
    if (!stackHistory.find((x) => x == el)) {
      stackHistory.push(el)
      // order : additionalData > dataset > default
      const data = {
        ...sections,
        interaction: 'section_view',
        ...(typeof additionalData == 'object' ? additionalData : {}),
      }
      console.log('---->', data)
      window.dataLayer.push(data)
    }

    lastSectionView.value = `${sections.micrositeSectionNumber}:${sections.micrositeSectionName}`
  }
  const pushActionEvent = (additionalData, el) => {
    if (typeof additionalData === 'string') additionalData = { micrositeSectionContent: additionalData }

    const data = {
      event: 'standardMicrositeTrackingEvent',
      ...(el && hasDataset(el, 'floatingButton') ? { micrositeSpecialValue: lastSectionView.value } : {}),
      ...additionalData,
      ...(el ? getSectionDataSets(el) : {}),
    }
    console.log(data)
    window.dataLayer.push(data)
  }

  return {
    pushSectionViewEvent,
    pushActionEvent,
  }
})
