import GaEvent from './GaEvent'
// import GaHover from './GaHover'
import GaSectionView from './GaSectionView'
// import GaSwiperDot from './GaSwiperDot'

export default {
  install: (app) => {
    app.use(GaEvent).use(GaSectionView)
  },
}
