import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import { createPinia } from 'pinia'
import ObserveVisibility from 'vue-observe-visibility'

import App from './App.vue'
import { router } from './router/router'
import directives from '@/directives'

createApp(App)
  .use(createPinia())
  .use(router)
  .use(
    // https://www.npmjs.com/package/@gtm-support/vue-gtm
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      enabled: import.meta.env.NODE_ENV === 'production',
      debug: import.meta.env.NODE_ENV !== 'production',
      loadScript: false,
    })
  )
  .use(directives)
  .use(ObserveVisibility)
  .mount('#app')

// import { createApp } from 'vue'
// import Person from './Person.vue'
// createApp(Person).mount('#app')
